import React, { FC } from 'react';

import { PageTitleProps } from './models';
import './styles.scss';

const PageTitle: FC<PageTitleProps> = ({ pageTitle }) => (
  <div className="page-title-container" data-testid="page-title">
    <h1 className="page-title">{pageTitle}</h1>
  </div>
);

export default PageTitle;
