import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import SideMenu from 'components/SideMenu';
import PageTitle from 'components/PageTitle';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import Layout from 'components/Layout';

import './AboutScalp.scss';
import { AboutScalpPageProps } from './models';
import classNames from 'classnames';

const AboutScalpPage: FC<AboutScalpPageProps> = ({
  data: {
    page: { id, title, text, description, image, seo, fullWidth },
    allMenuItems: { nodes },
    menuTitle: {
      nodes: [{ sidemenuTitle }],
    },
  },
  pageContext: {
    breadcrumb: { crumbs },
  },
}) => {
  const crumbsSettings = {
    crumbs,
  };

  return (
    <Layout crumbsSettings={crumbsSettings} seo={seo}>
      <Container fluid className={classNames('scalp-page', {
          'full-width': fullWidth=== '1',
        })}>
        <Row>
          { fullWidth == '1' ? null : 
            <Col lg={3} sm={12}>
              <SideMenu menuTitle={sidemenuTitle} menuItems={nodes} activeId={id} />
            </Col>
          }
          <Col lg={9} sm={12}>
            <PageTitle pageTitle={title} />
            {description ? <p>{description}</p> : null}
            {text ? (
              <DangerouslySetInnerHtml
                data-testid="scalp-text"
                className="scalp-page__text"
                html={text}
              />
            ) : null}
            {image ? (
              <UmbracoImage image={image} alt={`image-${title}`} className="scalp-page__image" />
            ) : null}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query AboutScalpQuery($id: String) {
    page: aboutScalp(id: { eq: $id }) {
      id
      link
      title
      text
      description
      image {
        fallbackUrl
        fluid {
          srcSet
          base64
        }
      }
      fullWidth  
      seo {
        seoMetaDescription
        seoMetaKeywords
        seoMetaTitle
      }
    }
    allMenuItems: allAboutScalp(filter: { hasChildren: { eq: false } }) {
      nodes {
        link
        title
        hasChildren
        id
      }
    }
    menuTitle: allAboutScalp(filter: { hasChildren: { eq: true } }) {
      nodes {
        sidemenuTitle
      }
    }
  }
`;

export default AboutScalpPage;